import Vue from 'vue';
import store from '@/store';
import { DASHBOARD_WIDGET_TYPES, PRODUCTS } from "@/config/enums";
import { getIndexedArray } from "@/shared/proxies";

const WidgetsIndexedArray = getIndexedArray('typeId');

export const getAllDashboardWidgets = () => {
  return new WidgetsIndexedArray([
    {
      typeId: DASHBOARD_WIDGET_TYPES.SMART_MARKET_TRENDS,
      title: Vue.prototype.$vDict('dashboard.widget_market_trends_title.text'),
      description: Vue.prototype.$vDict('dashboard.widget_market_trends_description.text'),
      icon: 'widget-market-trends',
      accessComponent: 'wg_market_trends',
    },
    {
      typeId: DASHBOARD_WIDGET_TYPES.SMART_NEWS,
      title: Vue.prototype.$vDict('dashboard.widget_news_title.text'),
      description: Vue.prototype.$vDict('dashboard.widget_news_description.text'),
      icon: 'widget-news',
      accessComponent: 'wg_news',
    },
    {
      typeId: DASHBOARD_WIDGET_TYPES.SMART_PROJECT_EXPLORER,
      title: Vue.prototype.$vDict('dashboard.widget_project_explorer_title.text'),
      description: Vue.prototype.$vDict('dashboard.widget_project_explorer_description.text'),
      icon: 'widget-project-explorer',
      accessComponent: 'wg_project_explorer',
    },
    {
      typeId: DASHBOARD_WIDGET_TYPES.SMART_FIND_ME,
      title: Vue.prototype.$vDict('dashboard.widget_find_me_title.text'),
      description: Vue.prototype.$vDict('dashboard.widget_find_me_description.text'),
      icon: 'widget-find-me',
      accessComponent: 'wg_find_me',
    },
    // {
    //   typeId: DASHBOARD_WIDGET_TYPES.SMART_CUSTOM_NOTIFICATIONS,
    //   title: Vue.prototype.$vDict('dashboard.widget_custom_email_notif_title.text'),
    //   description: Vue.prototype.$vDict('dashboard.widget_custom_email_notif_description.text'),
    //   icon: 'widget-custom-email-notif',
    //   accessComponent: 'wg_custom_email_notif',
    // },
    // {
    //   typeId: DASHBOARD_WIDGET_TYPES.SMART_SAVE_SEARCH,
    //   title: Vue.prototype.$vDict('dashboard.widget_save_search_title.text'),
    //   description: Vue.prototype.$vDict('dashboard.widget_save_search_description.text'),
    //   icon: 'widget-save-search',
    //   accessComponent: 'wg_save_search',
    // },
  ]);
};

export const getAllowedDashboardWidgets = () => {
  const userCountryId = store.getters['Account/getSettingsValue']('client.country_id');
  const allowedWidgetTypesByProducts = Vue.prototype.$lFind('global.countries', {
    id: userCountryId,
    prop: 'dashboard_widget_types',
  }) || {};
  const allowedWidgetTypes = allowedWidgetTypesByProducts[PRODUCTS.SMART] || [];

  if (!allowedWidgetTypes.length) {
    return [];
  }

  const allWidgets = getAllDashboardWidgets();
  return allWidgets
    .filter(widget => allowedWidgetTypes.includes(widget.typeId))
    .map(widget => ({
      ...widget,
      hasAccess: store.getters['Account/hasComponent'](widget.accessComponent),
    }));
};
